import React from 'react';
import './YearMatrixTable.scss';
import moment from 'moment';
import 'moment/locale/uk';
import { useAppSelector } from '../../hooks/redux';

export const YearMatrixTable = () => {
  const { mainString } = useAppSelector((state) => state.storeReducer);

  const generateRows = () => {
    const rows = [];

    const formattedDate =
      mainString.slice(0, 2).join('') +
      '.' +
      mainString.slice(2, 4).join('') +
      '.' +
      mainString.slice(4, 9).join('');

    // Початкова дата
    const currentDate = moment(formattedDate, 'DD.MM.YYYY');

    // Генеруємо 12 рядків
    for (let i = 0; i < 12; i++) {
      const startDateString = currentDate.format('DD MMMM');
      // console.log(startDateString);
      const endDateString = currentDate
        .clone()
        .add(1, 'months')
        .subtract(1, 'days')
        .format('DD MMMM');
      // console.log(endDateString);

      rows.push(
        <tr key={i}>
          <td className="th_xs">{`${i + 1}`}</td>
          <td>{startDateString}</td>
          <td>{endDateString}</td>
        </tr>
      );

      // Переходимо до наступного місяця
      currentDate.add(1, 'months');
    }
    return rows;
  };
  return (
    <div className="matrix-year-table">
      <h3>ПЕРСОНАЛЬНИЙ МІСЯЦЬ</h3>
      <table>
        <tr className="th_header">
          <th className="th_table th_xs td_number">№</th>
          <th className="th_table th_main">З</th>
          <th className="th_table th_main">По</th>
        </tr>
        <tbody>{generateRows()}</tbody>
      </table>
    </div>
  );

  // return (
  //   <div className="matrix-year-table">
  //     <h3>ПЕРСОНАЛЬНИЙ МІСЯЦЬ</h3>
  //     <table>
  //       <tbody>
  //         <tr className="th_header">
  //           <th className="th_table th_xs td_number">№</th>
  //           <th className="th_table th_main">З</th>
  //           <th className="th_table th_main">По</th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">1</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">2</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">3</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">4</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">5</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">6</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">7</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">8</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">9</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">10</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">11</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //         <tr className="">
  //           <th className="th_table th_xs td_number">12</th>
  //           <th className="th_table th_main"></th>
  //           <th className="th_table th_main"></th>
  //         </tr>
  //       </tbody>
  //     </table>
  //   </div>
  // );
};
