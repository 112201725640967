import React from 'react';
import './MarixMain.scss';
import RedArrow from '../../assets/1.png';
import BlueArrow from '../../assets/2.png';
import Heart from '../../assets/heart2.png';
import Dollar from '../../assets/dollar1.png';
import SmallArrow from '../../assets/arrowSmall.svg';

import {
  getSum,
  largeCircleValue1,
  largeCircleValue2,
  largeCircleValue3,
  largeCircleValue4,
  largeCircleValue5,
  largeCircleValue6,
  largeCircleValue7,
  largeCircleValue8,
  largeCircleValueCenter,
  mediumCircleK27,
} from '../../functions/calculator';
import { useAppSelector } from '../../hooks/redux';
import { IUserData, IUsersDataCompatibility } from '../../types';
import { Purposes } from '../Purposes/Purposes';

interface IMatrixCompatibilityProps {
  mainString: string[];
  mainString2: string[];
  userData: IUserData;
  getCirclesValue: (mainString: string[], birth: string) => { [key: string]: number };
  getEnergyValue: (mainString: string[], birth: string) => { [key: string]: number };
}

export const MatrixCompatibility = ({
  mainString,
  mainString2,
  userData,
  getCirclesValue,
  getEnergyValue,
}: IMatrixCompatibilityProps) => {
  const { userDataPersonal } = useAppSelector((state) => state.storeReducer);

  return (
    <div className="matrix_wrapper matrix-compatibility" id="matrix-compatibility-id">
      <div id="matrix-compatibility">
        <h2>{userData.name}</h2>
        <div className="circle circle_large" id="large-center">
          <div className="circle circle_large_inside" id="large-center_inside">
            {/*{largeCircleValueCenter(mainString, userData.birth)}*/}
            {getCirclesValue(mainString, userData.birth).circleLargeCenter}
          </div>
        </div>
        <div className="circle circle_large" id="large-1">
          <div className="circle circle_large_inside" id="large-1_inside">
            {/*{largeCircleValue1(mainString, userData.birth)}*/}
            {getCirclesValue(mainString, userData.birth).circleLarge1}
          </div>
        </div>
        <div className="circle circle_large circle_large_not-inside" id="large-2">
          {/*{largeCircleValue2(mainString, userData.birth)}*/}
          {getCirclesValue(mainString, userData.birth).circleLarge2}
        </div>
        <div className="circle circle_large circle_large_not-inside" id="large-3">
          {/*{largeCircleValue3(mainString, userData.birth)}*/}
          {getCirclesValue(mainString, userData.birth).circleLarge3}
        </div>
        <div className="circle circle_large" id="large-4">
          <div className="circle circle_large_inside" id="large-4_inside">
            {/*{largeCircleValue4(mainString, userData.birth)}*/}
            {getCirclesValue(mainString, userData.birth).circleLarge4}
          </div>
        </div>
        <div className="circle circle_large" id="large-5">
          <div className="circle circle_large_inside" id="large-5_inside">
            {/*{largeCircleValue5(mainString, userData.birth)}*/}
            {getCirclesValue(mainString, userData.birth).circleLarge5}
          </div>
        </div>
        <div className="circle circle_large circle_large_not-inside" id="large-6">
          {/*{largeCircleValue6(mainString, userData.birth)}*/}
          {getCirclesValue(mainString, userData.birth).circleLarge6}
        </div>
        <div className="circle circle_large circle_large_not-inside" id="large-7">
          {/*{largeCircleValue7(mainString, userData.birth)}*/}
          {getCirclesValue(mainString, userData.birth).circleLarge7}
        </div>
        <div className="circle circle_large" id="large-8">
          <div className="circle circle_large_inside" id="large-8_inside">
            {/*{largeCircleValue8(mainString, userData.birth)}*/}
            {getCirclesValue(mainString, userData.birth).circleLarge8}
          </div>
        </div>
        {/*/!*<div className="circle circle_large" id="large_center-circle"></div>*!/*/}
        <div className="square" id="square-1"></div>
        <div className="square" id="square-2"></div>

        <hr id="hr_top-left" />
        <hr id="hr_top-right" />
        <hr id="hr_top-left-2" />
        <hr id="hr_top-right-2" />
        <hr id="hr_bot-left-2" />
        <hr id="hr_bot-right-2" />
        <hr id="hr_bot-left" />
        <hr id="hr_bot-right" />
        <hr id="hr_center-vertical" />
        <hr id="hr_center-horizontal" />

        <hr id="hr_rotate-1" />
        <hr id="hr_rotate-2" />
        <p className="line" id="men-line">
          Лінія чоловічого роду
        </p>
        <p className="line" id="women-line">
          Лінія жіночого роду
        </p>

        <img className="heart" id="heart" src={Heart} alt="" />
        <img className="dollar" id="dollar" src={Dollar} alt="" />

        <div className="circle circle_medium" id="medium-1">
          <div className="circle circle_medium_inside" id="medium-1_inside">
            {getCirclesValue(mainString, userData.birth).mediumCircle1}
          </div>
        </div>
        <div className="circle circle_medium circle_medium_not-inside" id="medium-2">
          {getCirclesValue(mainString, userData.birth).mediumCircle2}
        </div>
        <div className="circle circle_medium circle_medium_not-inside" id="medium-3">
          {getCirclesValue(mainString, userData.birth).mediumCircle3}
        </div>
        <div className="circle circle_medium" id="medium-4">
          <div className="circle circle_medium_inside" id="medium-4_inside">
            {getCirclesValue(mainString, userData.birth).mediumCircle4}
          </div>
        </div>
        <div className="circle circle_medium circle_medium_not-inside" id="medium-5">
          {getCirclesValue(mainString, userData.birth).mediumCircle5}
        </div>
        <div className="circle circle_medium circle_medium_not-inside" id="medium-6">
          {getCirclesValue(mainString, userData.birth).mediumCircle6}
        </div>
        <div className="circle circle_medium circle_medium_not-inside" id="medium-7">
          {getCirclesValue(mainString, userData.birth).mediumCircle7}
        </div>
        <div className="circle circle_medium circle_medium_not-inside" id="medium-8">
          {getCirclesValue(mainString, userData.birth).mediumCircle8}
        </div>

        <div className="circle circle_small circle_small_not-inside" id="small-1">
          <div className="circle circle_small_inside" id="small-1_inside">
            {getCirclesValue(mainString, userData.birth).smallCircle1}
          </div>
        </div>
        <div className="circle circle_small circle_small_not-inside" id="small-2">
          {getCirclesValue(mainString, userData.birth).smallCircle2}
        </div>
        <div className="circle circle_small circle_small_not-inside" id="small-3">
          {getCirclesValue(mainString, userData.birth).smallCircle3}
        </div>
        <div className="circle circle_small" id="small-4">
          <div className="circle circle_small_inside" id="small-4_inside">
            {getCirclesValue(mainString, userData.birth).smallCircle4}
          </div>
        </div>
        <div className="circle circle_small" id="small-5">
          <div className="circle circle_small_inside" id="small-5_inside">
            {getCirclesValue(mainString, userData.birth).smallCircle5}
          </div>
        </div>
        <div className="circle circle_small" id="small-6">
          <div className="circle circle_small_inside" id="small-6_inside">
            {getCirclesValue(mainString, userData.birth).smallCircle6}
          </div>
        </div>
        <div className="circle circle_small" id="small-7">
          <div className="circle circle_small_inside" id="small-7_inside">
            {getCirclesValue(mainString, userData.birth).smallCircle7}
          </div>
        </div>
        <div className="circle circle_small circle_small_not-inside" id="small-8">
          {getCirclesValue(mainString, userData.birth).smallCircle8}
        </div>
        <div className="circle circle_small circle_small_not-inside" id="small-9">
          {getCirclesValue(mainString, userData.birth).smallCircle9}
        </div>
        <div className="circle circle_small circle_small_not-inside" id="small-10">
          {getCirclesValue(mainString, userData.birth).smallCircle10}
        </div>
        <div className="circle circle_small circle_small_not-inside" id="small-11">
          {getCirclesValue(mainString, userData.birth).smallCircle11}
        </div>
        <div className="circle circle_small circle_small_not-inside" id="small-12">
          {getCirclesValue(mainString, userData.birth).smallCircle12}
        </div>
        <div className="circle circle_small" id="small-13">
          <div className="circle circle_small_inside" id="small-13_inside">
            {getCirclesValue(mainString, userData.birth).smallCircle13}
          </div>
        </div>

        <div className="circle circle_small circle_small_not-inside" id="small-14">
          {getCirclesValue(mainString, userData.birth).smallCircle14}
        </div>
        <div className="circle circle_small circle_small_not-inside" id="small-15">
          {getSum(
            largeCircleValueCenter(mainString, userData.birth) +
              getSum(
                largeCircleValue2(mainString, userData.birth) +
                  largeCircleValue3(mainString, userData.birth) +
                  largeCircleValue6(mainString, userData.birth) +
                  largeCircleValue7(mainString, userData.birth)
              )
          )}
        </div>

        {/*<p id="comfort-zone">ЗОНА КОМФОРТУ</p>*/}

        {/*<div className="circle circle_xs" id="xs-1"></div>*/}
        {/*<div className="circle circle_xs" id="xs-2"></div>*/}
        {/*<div className="circle circle_xs" id="xs-3"></div>*/}
        {/*<div className="circle circle_xs" id="xs-4"></div>*/}
        {/*<div className="circle circle_xs" id="xs-5"></div>*/}
        {/*<div className="circle circle_xs" id="xs-6"></div>*/}
        {/*<div className="circle circle_xs" id="xs-7"></div>*/}
        {/*<div className="circle circle_xs" id="xs-8"></div>*/}
        {/*<div className="circle circle_xs" id="xs-9"></div>*/}
        {/*<div className="circle circle_xs" id="xs-10"></div>*/}
        {/*<div className="circle circle_xs" id="xs-11"></div>*/}
        {/*<div className="circle circle_xs" id="xs-12"></div>*/}
        {/*<div className="circle circle_xs" id="xs-13"></div>*/}
        {/*<div className="circle circle_xs" id="xs-14">*/}
        {/*  ЗК*/}
        {/*</div>*/}

        {/*<div className="small-arrow arrow-right" id="arrow-1">*/}
        {/*  <p className="years years_right">20 років</p>*/}
        {/*  <img className="small-arrow small-arr-img" src={SmallArrow} alt="" />*/}
        {/*</div>*/}
        {/*<div className="small-arrow arrow-right" id="arrow-2">*/}
        {/*  <p className="years years_right">30 років</p>*/}
        {/*  <img className="small-arrow small-arr-img" src={SmallArrow} alt="" />*/}
        {/*</div>*/}
        {/*<div className="small-arrow arrow-right" id="arrow-3">*/}
        {/*  <p className="years years_right">40 років</p>*/}
        {/*  <img className="small-arrow small-arr-img" src={SmallArrow} alt="" />*/}
        {/*</div>*/}
        {/*<div className="small-arrow arrow-right" id="arrow-4">*/}
        {/*  <p className="years years_right">50 років</p>*/}
        {/*  <img className="small-arrow small-arr-img" src={SmallArrow} alt="" />*/}
        {/*</div>*/}
        {/*<div className="small-arrow arrow-left" id="arrow-5">*/}
        {/*  <p className="years years_left">10 років</p>*/}
        {/*  <img className="small-arrow small-arr-img" src={SmallArrow} alt="" />*/}
        {/*</div>*/}
        {/*<div className="small-arrow arrow-left" id="arrow-6">*/}
        {/*  <p className="years years_left">0 років</p>*/}
        {/*  <img className="small-arrow small-arr-img" src={SmallArrow} alt="" />*/}
        {/*</div>*/}
        {/*<div className="small-arrow arrow-left" id="arrow-7">*/}
        {/*  <p className="years years_left">70 років</p>*/}
        {/*  <img className="small-arrow small-arr-img" src={SmallArrow} alt="" />*/}
        {/*</div>*/}
        {/*<div className="small-arrow arrow-left" id="arrow-8">*/}
        {/*  <p className="years years_left">60 років</p>*/}
        {/*  <img className="small-arrow small-arr-img" src={SmallArrow} alt="" />*/}
        {/*</div>*/}
        <div className="small-arrow arrow-right" id="arrow-1">
          <p className="years years_right" id="years-20">
            20 років
          </p>
        </div>
        <div className="small-arrow arrow-right" id="arrow-2">
          <p className="years years_right" id="years-30">
            30 років
          </p>
        </div>
        <div className="small-arrow arrow-right" id="arrow-3">
          <p className="years years_right" id="years-40">
            40 років
          </p>
        </div>
        <div className="small-arrow arrow-right" id="arrow-4">
          <p className="years years_right" id="years-50">
            50 років
          </p>
        </div>
        <div className="small-arrow arrow-left" id="arrow-5">
          <p className="years years_left" id="years-10">
            10 років
          </p>
        </div>
        <div className="small-arrow arrow-left" id="arrow-6">
          <p className="years years_left" id="years-0">
            0 років
          </p>
        </div>
        <div className="small-arrow arrow-left" id="arrow-7">
          <p className="years years_left" id="years-70">
            70 років
          </p>
        </div>
        <div className="small-arrow arrow-left" id="arrow-8">
          <p className="years years_left" id="years-60">
            60 років
          </p>
        </div>
        <hr id="hr_dashed" />
      </div>

      {/*******************************************/}
      <div className="purposes">
        <h3>Призначення</h3>
        <div className="purposes_top">
          <div className="purpose personal">
            <h3 className="purpose_h3">Особисте</h3>
            <h3 className="purpose_h3 purpose_h3-description">
              Наші задачі на духовному та матеріальному рівні (20-40 років)
            </h3>

            <div className="rect">
              <div className="content">
                <div className="content_row">
                  Небо:
                  <div className="circle_purpose">
                    {getSum(
                      getCirclesValue(mainString, userData.birth).circleLarge1 +
                        getCirclesValue(mainString, userData.birth).circleLarge8
                    )}
                  </div>
                </div>
                <div className="content_row">
                  Земля:
                  <div className="circle_purpose">
                    {getSum(
                      getCirclesValue(mainString, userData.birth).circleLarge4 +
                        getCirclesValue(mainString, userData.birth).circleLarge5
                    )}
                  </div>
                </div>
              </div>
              <div className="right-border">
                <span className="span left-brace-tl"></span>
                <span className="span left-brace-tr"></span>
                <br />
                <span className="span left-brace-bl"></span>
                <span className="span left-brace-br"></span>
              </div>
              <div className="circle_purpose circle_sum">
                {getSum(
                  getSum(
                    getCirclesValue(mainString, userData.birth).circleLarge1 +
                      getCirclesValue(mainString, userData.birth).circleLarge8
                  ) +
                    getSum(
                      getCirclesValue(mainString, userData.birth).circleLarge4 +
                        getCirclesValue(mainString, userData.birth).circleLarge5
                    )
                )}
              </div>
            </div>
          </div>
          <div className="purpose social">
            <h3 className="purpose_h3">Соціальне</h3>
            <h3 className="purpose_h3 purpose_h3-description">
              Взаємодія з соціумом. Те, яку цінність ми несемо людям (40-60 років)
            </h3>
            <div className="rect">
              <div className="content">
                <div className="content_row word">
                  <span className="word">Ч:</span>
                  <div className="circle_purpose">
                    {getSum(
                      getSum(
                        getCirclesValue(mainString, userData.birth).circleLarge2 +
                          getCirclesValue(mainString, userData.birth).circleLarge7
                      )
                    )}
                  </div>
                </div>
                <div className="content_row word">
                  <span className="word">Ж:</span>
                  <div className="circle_purpose">
                    {getSum(
                      getSum(
                        getCirclesValue(mainString, userData.birth).circleLarge3 +
                          getCirclesValue(mainString, userData.birth).circleLarge6
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="right-border">
                <span className="span left-brace-tl"></span>
                <span className="span left-brace-tr"></span>
                <br />
                <span className="span left-brace-bl"></span>
                <span className="span left-brace-br"></span>
              </div>
              <div className="circle_purpose circle_sum">
                {getSum(
                  getSum(
                    getSum(
                      getCirclesValue(mainString, userData.birth).circleLarge2 +
                        getCirclesValue(mainString, userData.birth).circleLarge7
                    )
                  ) +
                    getSum(
                      getSum(
                        getCirclesValue(mainString, userData.birth).circleLarge3 +
                          getCirclesValue(mainString, userData.birth).circleLarge6
                      )
                    )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="purpose purpose_bottom">
          <div className="purpose_general">
            <h3 className="purpose_h3">Духовне</h3>
            <h3 className="purpose_h3 purpose_h3-description">
              Духовний залік. Саме на опрацювання цієї енергії нам дається більшість випробувань
            </h3>
            <div className="">
              <div className="content circle_solo">
                <div className="circle" id="circle_soul">
                  {getSum(
                    getSum(
                      getSum(
                        getSum(
                          getCirclesValue(mainString, userData.birth).circleLarge1 +
                            getCirclesValue(mainString, userData.birth).circleLarge8
                        ) +
                          getSum(
                            getCirclesValue(mainString, userData.birth).circleLarge4 +
                              getCirclesValue(mainString, userData.birth).circleLarge5
                          )
                      )
                    ) +
                      getSum(
                        getSum(
                          getSum(
                            getCirclesValue(mainString, userData.birth).circleLarge2 +
                              getCirclesValue(mainString, userData.birth).circleLarge7
                          )
                        ) +
                          getSum(
                            getSum(
                              getCirclesValue(mainString, userData.birth).circleLarge3 +
                                getCirclesValue(mainString, userData.birth).circleLarge6
                            )
                          )
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="matrix-appointment">*/}
      {/*  <h3>КАРТА ЖИТТЯ</h3>*/}
      {/*  <div className="life-map_wrapper">*/}
      {/*    <div className="life-map_items">*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle">*/}
      {/*          {getSum(*/}
      {/*            getCirclesValue(mainString, userData.birth).circleLarge1 +*/}
      {/*              getCirclesValue(mainString, userData.birth).circleLarge8*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Небесне призначення</div>*/}
      {/*      </div>*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle">*/}
      {/*          {getSum(*/}
      {/*            getCirclesValue(mainString, userData.birth).circleLarge4 +*/}
      {/*              getCirclesValue(mainString, userData.birth).circleLarge5*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Земне призначення</div>*/}
      {/*      </div>*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle circle-bg">*/}
      {/*          {getSum(*/}
      {/*            getSum(*/}
      {/*              getCirclesValue(mainString, userData.birth).circleLarge1 +*/}
      {/*                getCirclesValue(mainString, userData.birth).circleLarge8*/}
      {/*            ) +*/}
      {/*              getSum(*/}
      {/*                getCirclesValue(mainString, userData.birth).circleLarge4 +*/}
      {/*                  getCirclesValue(mainString, userData.birth).circleLarge5*/}
      {/*              )*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Особисте призначення</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="life-map_items">*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle">*/}
      {/*          {getSum(*/}
      {/*            getSum(*/}
      {/*              getCirclesValue(mainString, userData.birth).circleLarge2 +*/}
      {/*                getCirclesValue(mainString, userData.birth).circleLarge7*/}
      {/*            )*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Чоловіче призначення</div>*/}
      {/*      </div>*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle">*/}
      {/*          {getSum(*/}
      {/*            getSum(*/}
      {/*              getCirclesValue(mainString, userData.birth).circleLarge3 +*/}
      {/*                getCirclesValue(mainString, userData.birth).circleLarge6*/}
      {/*            )*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Жіноче призначення</div>*/}
      {/*      </div>*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle circle-bg">*/}
      {/*          {getSum(*/}
      {/*            getSum(*/}
      {/*              getSum(*/}
      {/*                getCirclesValue(mainString, userData.birth).circleLarge2 +*/}
      {/*                  getCirclesValue(mainString, userData.birth).circleLarge7*/}
      {/*              )*/}
      {/*            ) +*/}
      {/*              getSum(*/}
      {/*                getSum(*/}
      {/*                  getCirclesValue(mainString, userData.birth).circleLarge3 +*/}
      {/*                    getCirclesValue(mainString, userData.birth).circleLarge6*/}
      {/*                )*/}
      {/*              )*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Соціальне призначення</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="life-map_items">*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle">*/}
      {/*          {getSum(*/}
      {/*            getSum(*/}
      {/*              getSum(*/}
      {/*                getCirclesValue(mainString, userData.birth).circleLarge1 +*/}
      {/*                  getCirclesValue(mainString, userData.birth).circleLarge8*/}
      {/*              ) +*/}
      {/*                getSum(*/}
      {/*                  getCirclesValue(mainString, userData.birth).circleLarge4 +*/}
      {/*                    getCirclesValue(mainString, userData.birth).circleLarge5*/}
      {/*                )*/}
      {/*            )*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Особисте призначення</div>*/}
      {/*      </div>*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle">*/}
      {/*          {getSum(*/}
      {/*            getSum(*/}
      {/*              getSum(*/}
      {/*                getCirclesValue(mainString, userData.birth).circleLarge2 +*/}
      {/*                  getCirclesValue(mainString, userData.birth).circleLarge7*/}
      {/*              )*/}
      {/*            ) +*/}
      {/*              getSum(*/}
      {/*                getSum(*/}
      {/*                  getCirclesValue(mainString, userData.birth).circleLarge3 +*/}
      {/*                    getCirclesValue(mainString, userData.birth).circleLarge6*/}
      {/*                )*/}
      {/*              )*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Соціальне призначення</div>*/}
      {/*      </div>*/}
      {/*      <div className="life-map_item">*/}
      {/*        <div className="life-map_item-circle circle-bg">*/}
      {/*          {getSum(*/}
      {/*            getSum(*/}
      {/*              getSum(*/}
      {/*                getSum(*/}
      {/*                  getCirclesValue(mainString, userData.birth).circleLarge1 +*/}
      {/*                    getCirclesValue(mainString, userData.birth).circleLarge8*/}
      {/*                ) +*/}
      {/*                  getSum(*/}
      {/*                    getCirclesValue(mainString, userData.birth).circleLarge4 +*/}
      {/*                      getCirclesValue(mainString, userData.birth).circleLarge5*/}
      {/*                  )*/}
      {/*              )*/}
      {/*            ) +*/}
      {/*              getSum(*/}
      {/*                getSum(*/}
      {/*                  getSum(*/}
      {/*                    getCirclesValue(mainString, userData.birth).circleLarge2 +*/}
      {/*                      getCirclesValue(mainString, userData.birth).circleLarge7*/}
      {/*                  )*/}
      {/*                ) +*/}
      {/*                  getSum(*/}
      {/*                    getSum(*/}
      {/*                      getCirclesValue(mainString, userData.birth).circleLarge3 +*/}
      {/*                        getCirclesValue(mainString, userData.birth).circleLarge6*/}
      {/*                    )*/}
      {/*                  )*/}
      {/*              )*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*        <div className="life-map_item-title">Духовне призначення</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
